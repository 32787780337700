// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mission-trip-js": () => import("./../../../src/pages/mission-trip.js" /* webpackChunkName: "component---src-pages-mission-trip-js" */),
  "component---src-pages-teaching-js": () => import("./../../../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */),
  "component---src-template-blog-template-js": () => import("./../../../src/template/blog-template.js" /* webpackChunkName: "component---src-template-blog-template-js" */),
  "component---src-template-general-template-js": () => import("./../../../src/template/general-template.js" /* webpackChunkName: "component---src-template-general-template-js" */),
  "component---src-template-mission-template-js": () => import("./../../../src/template/mission-template.js" /* webpackChunkName: "component---src-template-mission-template-js" */),
  "component---src-template-teaching-template-js": () => import("./../../../src/template/teaching-template.js" /* webpackChunkName: "component---src-template-teaching-template-js" */)
}

